<template>
  <div class="app-home-screen">
    <div class="app-home-screen__content">
      <img
        class="app-home-screen__logo"
        alt="National Garden Scheme logo"
        src="@/assets/images/logo.png"
      >
      <h2 class="app-home-screen__heading">Find a garden</h2>
      <b-form-group class="app-home-screen__radio-options" v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="location-radios" size="lg" value="current-location" @change="handleSearchTypeChange">
          <font-awesome-icon class="app-home-screen__radio-options--icon" :icon="['far', 'check']" />
          Use current location
        </b-form-radio>
        <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="location-radios" size="lg" value="custom-location" @change="handleSearchTypeChange">
          <font-awesome-icon class="app-home-screen__radio-options--icon" :icon="['far', 'check']" />
          Specify postcode or town
        </b-form-radio>
        </b-form-group>
      <div class="app-home-screen__input-container">
        <b-form-input
          ref="input"
          :class="[
            'app-home-screen__input-container--input',
            showError && 'app-home-screen__input-container--input--error',
          ]"
          v-model="searchValue"
          :placeholder="searchPlaceholder"
          :readonly="currentLocationSelected"
          @input="debounceSearchLocations()"
          @focus="handleInputFocus"
          @focusout="handleInputFocusOut"
          v-on:keyup.enter="searchForGardens"
        >
        </b-form-input>
        <font-awesome-icon
          class="app-home-screen__input-container--icon"
          :icon="['far', currentLocationSelected ? 'location' : 'search']"
        />
        <div id="search-results-dropdown" v-if="resultsToDisplay">
          <ul
            class="dropdown-menu"
            :class="[showResultsDropDown ? 'show' : 'hide']">
            <li
              v-for="(result, resultIndex) in resultsToDisplay"
              :key="resultIndex"
              @click="selectOption(result)"
            >
              <div class="dropdown-item">
                {{ result.formatted_address }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p class="app-home-screen__error-message" v-if="showError"> {{ showError }} </p>
      <FilterDaysOpenModal
        v-if="daysOpen"
        :section="daysOpen"
        class="app-home-screen__days-open-dropdown"
        showLeftIcon
        @saved-filter="handleSaveFilter"
      />
      <b-form-group class="app-home-screen__button-options" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="bookingSelected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="booking-type"
            buttons
          ></b-form-radio-group>
      </b-form-group>
      <b-button
        class="app-home-screen__btn ngs-font"
        variant="secondary"
        size="lg"
        @click="searchForGardens"
      >
        Search
      </b-button>
      <div class="app-home-screen__link-container">
        <a class="app-home-screen__link ngs-font" href="https://www.ngs.org.uk" >ngs.org.uk</a>
        <span class="app-home-screen__link-container--separator">&#11825;</span>
        <a class="app-home-screen__link ngs-font" href="https://ngs.org.uk/privacy-policy/" >Privacy policy</a>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mapApi from '@/api/endpoints/map'
import FilterDaysOpenModal from '@/components/header/filters/FilterDaysOpenModal'

export default {
  name: 'Search',

  components: {
    FilterDaysOpenModal,
  },

  data: () => ({
    selected: 'current-location',
    options: [
      { text: 'Regular openings', value: 'opening' },
      { text: 'By arrangement visits', value: 'by-arrangement' },
    ],
    bookingSelected: 'opening',
    searchValue: '',
    searchResults: [],
    displayResultsDropDown: false,
    selectedLocation: null,
    displayError: '',
    defaultRadius: {
      name: '10 miles',
      value: 10,
    },
  }),

  computed: {
    ...mapGetters('garden', [
      // 'searchResults',
    ]),
    ...mapGetters('filter', [
      'dynamicFilterSectionByKey',
      'dynamicFilterSections',
      'selectedFilters',
    ]),
    ...mapGetters('location', [
      'locationEngland',
    ]),
    daysOpen() {
      return this.dynamicFilterSectionByKey('days_open')
    },
    openingType() {
      return this.dynamicFilterSectionByKey('opening_type')
    },
    resultsToDisplay() {
      return this.searchResults;
    },
    currentLocationSelected() {
      return this.selected === 'current-location';
    },
    searchPlaceholder() {
      return this.currentLocationSelected ? 'Your current location' : 'Search by location'
    },
    showResultsDropDown() {
      return this.searchResults?.length && this.displayResultsDropDown;
    },
    showError() {
      return this.displayError;
    },
  },

  created() {
    if (!this.dynamicFilterSections) {
      this.fetchAllFiters()
    }

    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.addListener('keyboardWillShow', (info) => {
        document.querySelector('.app-home-screen__content').style.marginBottom = `${info.keyboardHeight}px`
      });

      Keyboard.addListener('keyboardWillHide', () => {
        document.querySelector('.app-home-screen__content').style.marginBottom = '0'
      });
    }
  },

  unmounted() {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.removeAllListeners()
    }
  },

  methods: {
    ...mapActions('garden', [
      'updateSearchTerm',
      'search',
    ]),
    ...mapMutations('garden', [
      'updateSearchTerm',
    ]),
    ...mapActions('location', [
      'locateUserGeoLocation',
      'getSelectedLocationBoundsAndUpdate',
      'selectRadius',
      'setUseCurrentLocation',
    ]),
    ...mapMutations('filter', [
      'updateTag',
    ]),
    ...mapActions('filter', [
      'fetchAllFiters',
    ]),

    debounceSearchLocations: debounce(function () {
      this.searchLocations(this.searchValue)
    }, 300),

    handleSaveFilter(event) {
      if (event.type === 'date_range') {
        this.updateTag({
          filter: 'date_open_from',
          options: event.options.date_open_from,
          type: 'date_range',
        })

        this.updateTag({
          filter: 'date_open_to',
          options: event.options.date_open_to,
          type: 'date_range',
        })
      } else {
        this.updateTag(event)
      }
    },

    selectOption(result) {
      this.searchValue = result?.formatted_address;
      this.selectedLocation = result;
      this.displayResultsDropDown = false;
    },
    async searchLocations() {
      if (this.searchValue?.length <= 1) {
        this.searchResults = [];
        return;
      }

      try {
        const response = await mapApi.location({ address: this.searchValue });

        if (response?.data?.results?.length) {
          this.searchResults = response?.data?.results ?? [];
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleSearchTypeChange() {
      if (this.currentLocationSelected) {
        this.searchValue = ''
        this.searchResults = [];
      } else {
        this.$refs.input.$el.focus()
      }
    },
    handleInputFocus() {
      this.displayResultsDropDown = true;
    },
    handleInputFocusOut() {
      setTimeout(() => {
        this.displayResultsDropDown = false;
      }, 100);
    },
    async searchForGardens() {
      const activeFilters = this.selectedFilters?.map((filter) => filter.options);

      if (!this.currentLocationSelected) {
        if (!this.searchValue?.trim()) {
          this.displayError = 'Please enter a location.'
          setTimeout(() => {
            this.displayError = ''
          }, 2000)
          return;
        }

        if (this.resultsToDisplay?.length && !this.selectedLocation) {
          this.selectOption(this.resultsToDisplay?.[0])
        }

        this.updateSearchTerm(this.searchValue);

        if (!activeFilters.includes(this.bookingSelected)) {
          this.updateTag({ filter: this.openingType.filter, type: this.openingType.display_type, options: this.bookingSelected })
        }
        this.selectRadius(this.defaultRadius)

        if (this.selectedLocation) {
          this.getSelectedLocationBoundsAndUpdate({
            location: this.selectedLocation,
            keepFilters: true,
          });
        } else {
          this.getSelectedLocationBoundsAndUpdate({
            location: this.locationEngland,
            keepFilters: true,
          });
        }
        return;
      }

      if (!activeFilters.includes(this.bookingSelected)) {
        this.updateTag({ filter: this.openingType.filter, type: this.openingType.display_type, options: this.bookingSelected })
      }
      this.selectRadius(this.defaultRadius)
      this.setUseCurrentLocation(true);
    },
  },
}
</script>

<style scoped lang="scss">
.app-home-screen {
  min-height: 100vh;
  background-color: $color-yellow-main;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 12px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__logo {
    height: 95px;
  }

  &__heading {
    margin: 0;
  }

  &__days-open-dropdown,
  &__button-options,
  &__radio-options {
    width: 100%;
    margin: 0;
  }

  &__radio-options {
    &--icon {
      color: white;
      font-size: 12px;
      position: absolute;
      right: 4px;
      display: none;
    }
  }

  &__input-container {
    width: 100%;
    position: relative;

    &--input {
      padding-left: 28px;

      &--error {
        border: 2px solid #9c0f05;
      }
    }

    &--icon {
      position: absolute;
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 7px;
      color: #495057;
    }
  }

  &__error-message {
    text-align: left;
    width: 100%;
    margin: -10px 0;
    color: #9c0f05;
  }

  &__btn {
    background-color: black;
    color: white;
    border: 1px solid black;
    padding: 9px 32px !important;
    width: 100%;
  }

  &__link-container {
    display: flex;
    gap: 6px;
    align-items: center;

    &--separator {
      font-size: 24px;
    }
  }

  &__link {
    font-size: 18px;
    text-decoration: underline;
    color: black;
  }
}
</style>

<style lang="scss">
.app-home-screen {
  .app-home-screen__radio-options {
    .custom-control {
      padding-left: 0;
    }

    .custom-control-label {
      display: inline-flex;
      flex-direction: row-reverse;
      gap: 8px;
      align-items: center;
      width: 100%;

      &::before{
        top: 0 !important;
        left: 0 !important;
        position: relative;
        display: block;
        margin-left: auto;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: black;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: none;
    }

    .custom-control-input:checked ~ .custom-control-label > .app-home-screen__radio-options--icon {
      display: block;
    }
  }

  .app-home-screen__button-options {
    .btn-secondary {
      border-color: #ced4da !important;
    }

    .active {
      background-color: #ced4da !important;
    }
  }

  .app-home-screen__days-open-dropdown {
    #filter-button-days-open-dropdown {
      width: 100% !important;
    }
  }

  .app-home-screen__days-open-dropdown {
    > .dropdown > .dropdown-menu {
      left: 0 !important;
    }

    .filter-dropdown {
      border-top: none !important;
    }
  }
}
</style>
