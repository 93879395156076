<template>
  <div class="filter-days-open-modal">
    <b-button
      variant="outline-secondary"
      class="filter-button"
      @click="displayModal"
    >
      {{ buttonLabel }}
      <font-awesome-icon class="right-icon" :icon="['far', 'chevron-down']"/>
    </b-button>
    <b-modal
      id="filter-days-open-modal"
      hide-footer
      centered
      content-class="shadow"
      @hide="handleCloseEvent"
      @show="setCalendarDetailsIfCustomRange"
    >
      <template #modal-title>
        <h3 class="m-0">{{ section.label }}</h3>
      </template>
      <div class="filter-days-open-modal__options">
        <b-dropdown-item-button
          class="filter-option filter-days-open-modal__options--option"
          v-for="(option, optionIndex) in section.options"
          :key="optionIndex"
          @click="selectTag(option)">
          <font-awesome-icon
            class="radio-button-icon"
            :icon="setActiveIcon(option.value)"
          />
          {{ option.label }}
        </b-dropdown-item-button>
        <b-dropdown-item-button
          class="custom-dates-button filter-days-open-modal__options--option"
          :class="selectedDates.start && selectedDates.end ? 'active' : ''"
          @click="activateCalendar()">
          <font-awesome-icon
            class="radio-button-icon"
            :icon="setActiveIcon('date_range')"/>
          Date range
        </b-dropdown-item-button>
      </div>
      <div fluid class="p-0 m-auto calendar-container" v-if="activeCalendar">
        <div
          class="calendar-wrapper d-flex flex-column flex-sm-row filter-days-open-modal__calendar">
          <vc-date-picker
            v-model="selectedDates"
            class="filter-days-open-modal__calendar"
            :value="null"
            color="yellow"
            is-range
            is-expanded
            :columns="1"
            :rows="1"
            :min-date='new Date()'
          />
        </div>
      </div>
      <b-button
        variant="primary"
        class="filter-days-open-modal__confirm"
        @click="handleSelectionConfirmation"
      >
        Confirm
      </b-button>
    </b-modal>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { find, includes } from 'lodash';

export default {
  name: 'FilterDaysOpenButton',

  props: {
    section: Object,
    filterSection: String,
    showLeftIcon: {
      type: Boolean,
      default: false,
    },
    selectedAsName: {
      type: Boolean,
      default: false,
    },
    disableFilters: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    selectedDates: {
      start: null,
      end: null,
    },
    radioIcons: {
      unSelected: ['fal', 'circle'],
      selected: ['fas', 'circle'],
    },
    optionValue: null,
    activeCalendar: false,
    calendarContexts: [],
    calendarDatas: [],
    leftContext: null,
    rightContext: null,
    displayDate: null,
    calendarsRequired: 2,
    initialDateSets: {
      fromDate: false,
      toDate: false,
    },
    savedFilter: null,
    confirmed: false,
  }),

  mounted() {
    this.$root.$on('bv::dropdown::hide', (bvEvent) => {
      if (bvEvent.componentId === 'filter-button-days-open-dropdown'
        && this.activeCalendar
        && (!this.fromDateValue || !this.toDateValue)
      ) {
        this.activeCalendar = false
      }
    })
  },

  computed: {
    ...mapGetters('filter', [
      'filterSelected',
      'sectionFilterSelected',
    ]),

    buttonLabel() {
      return this.sectionFilterSelectedLabel || this.optionValue || 'Next 28 days';
    },
    fromDateValue() {
      return this.selectedDates.start;
    },
    toDateValue() {
      return this.selectedDates.end;
    },
    sectionFilterSelectedValue() {
      return this.sectionFilterSelected('days_open')
    },
    sectionFilterSelectedLabel() {
      if (!this.sectionFilterSelected('days_open')) {
        return '';
      }

      if (this.fromDateValue && this.toDateValue) {
        return 'Date range'
      }

      return find(this.section.options, ['value', this.sectionFilterSelected('days_open')])?.label || '';
    },
  },
  watch: {
    selectedDates(date) {
      if (date.start || date.end) {
        this.optionValue = 'Date range';
        this.selectRange({
          date_open_from: date?.start || null,
          date_open_to: date?.end || null,
        });
      }
    },
    sectionFilterSelectedValue(value) {
      if (value) {
        this.optionValue = this.sectionFilterSelectedLabel;

        if (this.filterSelected('days_open', value) && this.activeCalendar) {
          this.activateCalendar()
        }
      }
    },
  },

  methods: {
    ...mapActions('filter', [
      'addFilter',
      'removeFilter',
    ]),

    setCalendarDetailsIfCustomRange() {
      const preSelectedFromDate = this.sectionFilterSelected('date_open_from');
      const preSelectedToDate = this.sectionFilterSelected('date_open_to');

      if (preSelectedFromDate.length) {
        // Dealing with Safari
        this.selectedDates.start = new Date(preSelectedFromDate.replace(/-/g, '/'));
      }

      if (preSelectedToDate.length) {
        this.selectedDates.end = new Date(preSelectedToDate.replace(/-/g, '/'));
      }

      if (preSelectedFromDate.length || preSelectedToDate.length) {
        this.activateCalendar()
      } else {
        this.activeCalendar = false
      }
    },

    displayModal() {
      this.$bvModal.show('filter-days-open-modal')
    },

    handleCloseEvent() {
      if (!this.confirmed) this.savedFilter = null;
    },

    setActiveIcon(option) {
      if (!this.savedFilter) {
        if (option === 'date_range') {
          return this.selectedDates.start && this.selectedDates.end ? this.radioIcons.selected : this.radioIcons.unSelected
        }

        return this.filterSelected(this.section.filter, option) ? this.radioIcons.selected : this.radioIcons.unSelected
      }

      return this.savedFilter?.options === option || (option === 'date_range' && this.savedFilter?.options?.date_open_from) ? this.radioIcons.selected : this.radioIcons.unSelected
    },

    handleSelectionConfirmation() {
      this.confirmed = true
      this.$emit('saved-filter', this.savedFilter);
      this.$bvModal.hide('filter-days-open-modal');
      this.confirmed = false
    },

    async activateCalendar() {
      this.activeCalendar = !this.activeCalendar
      this.savedFilter = {
        options: 'date_range',
      }
      if (this.selectedDate?.start && this.selectedDate?.end) {
        if (!this.activeCalendar) {
          this.selectedDates = {
            fromDate: null,
            toDate: null,
          }
          this.optionValue = null
        } else {
          this.optionValue = 'Date range'
        }
      }
    },

    selectedDefault() {
      if (includes(['radio'], this.section.display_type) && !this.sectionFilterSelected(this.section.filter).length) {
        this.selectTag(this.section.options[0])
      }
    },
    disabledDatesFN(date, calendaryearMonth) {
      const year = date.getFullYear()
      const month = date.getMonth()
      const ym = `${year}-${month}`
      return ym !== calendaryearMonth
    },

    async selectTag(option) {
      this.optionValue = option.label;
      let localOptionValue = option.value;

      if (includes(['checkbox', 'buttons'], this.section.display_type)) {
        localOptionValue = [option.value]
      }
      // await this.addFilter({ filter: this.section.filter, type: this.section.display_type, options: localOptionValue })
      this.savedFilter = { filter: this.section.filter, type: this.section.display_type, options: localOptionValue };
      this.activeCalendar = false
      this.selectedDates = {
        start: null,
        end: null,
      }
    },

    async selectRange(dates) {
      // To void issues with timezones (these will happen on summer time),
      // we get the timezone offset in milliseconds...
      const tzoffset = (new Date()).getTimezoneOffset() * 60000;

      // ...and use compensate for it when converting the date obj into a string
      const dateOpenFrom = new Date(dates.date_open_from - tzoffset)
        .toISOString().slice(0, 10);

      const dateOpenTo = new Date(dates.date_open_to - tzoffset)
        .toISOString().slice(0, 10);

      this.savedFilter = {
        filter: this.section.filter,
        type: 'date_range',
        options: {
          date_open_from: dateOpenFrom,
          date_open_to: dateOpenTo,
        },
      };
    },
  },
}
</script>
<style lang="scss">
  .vc-container {
    border: none;

    .vc-day {
      .vc-highlights {
        &.vc-day-layer {
          .vc-day-layer {
            &.vc-day-box-right-center {
              .vc-highlight.vc-highlight-base-start {
                background-color: $color-yellow-lighter !important;
              }
            }
            &.vc-day-box-center-center {
              .vc-highlight {
                background-color: $primary !important;
              }

              .vc-highlight.vc-highlight-base-middle {
                background-color: $color-yellow-lighter !important;
              }
            }
          }
        }
      }
    }

    .vc-day-content {
      &.vc-focusable {
        &:not(.is-disabled) {
          color: rgb(58, 58, 58) !important;
        }
      }
    }
  }

.filter-days-open-modal {
    &__options {
      display: flex;
      flex-wrap: wrap;
      list-style: none;

      &--option {
        width: 50%;

        .dropdown-item {
          padding: 0;
          margin: 0 0 12px;
        }
      }
    }

    > .btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        font-size: 12px;
      }
    }

    &__calendar {
      margin-bottom: 12px;
    }

    &__confirm {
      float: right;
    }

    .filter-option {
      &.selected {
        .dropdown-item{
          font-weight:600 !important;
        }
      }
    }
    @media (max-width: 1200px) {
      .btn-group,
      .btn-group-vertical,
      .dropdown {
        position: initial;
      }

      .dropdown-menu {
        top: -40px !important;
      }
    }
  }
</style>

<style scoped lang="scss">
  .filter-days-open-modal {
    margin-right: 0.5rem;
  }
  .filter-dropdown {
    min-width: 16rem;

    .custom-dates-button {
      text-align: left;
      padding: 0.25rem 0 0.25rem 1.45rem;
      width: 50%;
      border-radius: 0;
      font-size: 14px;

      svg {
        &.radio-button-icon {
          margin-right: 5px;
        }
      }

      @include media-breakpoint-down(lg) {
        background-color: $primary !important;
        border-color: $primary !important;
        padding-left: 0.45rem;
        margin-bottom: 0.5rem;

        &.active {
          font-weight: 600;
        }

        &:not(:disabled):not(.disabled):active {
          background-color: $primary !important;
          border-color: $primary !important;

          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
  .filter-range {
    min-width: 19rem;
    .filter-range-option-button {
      &:not(:last-child) {
        margin-right: 0.539rem;
      }
    }
  }
  .filter-list-options-wrapper {
    max-height: 9.388rem;
    overflow-y: auto;
  }

  .calendar-header-wrapper {
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .up-calendar-button {
      position: absolute;
      top: 7px;
      cursor: pointer;
      &.month-next {
        right: 0;
      }
    }
  }
  @include media(tablet) {
    .calendar-wrapper {
      border-radius: 0.25rem;
      background-color: #ffffff;
      border: 1px solid $color-yellow-light;
      .date-range-calendar {
        &:not(:first-child) {
          border-left: 1px solid $color-yellow-light;
          @include media(mobile) {
            border-left: none;
            border-top: 1px solid $color-yellow-light;
          }
        }
      }
    }
  }
</style>
